@tailwind base;
@tailwind components;
@tailwind utilities;

.font-sans {
  font-family: var(--font-sf-pro-display-light-sans), sans-serif;
}
.font-serif {
  font-family: var(--SF-Pro-Display-Regular-sans), sans-serif;
}

body {
  @apply font-sans font-normal text-neutral-800;
}
body a {
  @apply no-underline text-blue-700;
}
body p {
  @apply mb-4;
}
nav .navbar-menu a {
  @apply font-sans font-medium;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-serif font-normal leading-loose tracking-widest;
  line-height: 1.2em !important;
}
h1 {
  @apply text-4xl md:text-5xl lg:text-6xl mb-4;
}
h1.heading {
  @apply text-6xl md:text-7xl lg:text-8xl mb-4;
}
h2 {
  @apply text-3xl md:text-4xl lg:text-5xl mb-4;
}
h2.heading {
  @apply text-5xl md:text-6xl lg:text-7xl mb-4;
}
h3 {
  @apply text-2xl md:text-3xl lg:text-4xl mb-4;
}
h4 {
  @apply text-xl md:text-2xl lg:text-3xl mb-4;
}
h5 {
  @apply text-lg md:text-xl lg:text-2xl mb-4;
}
h6 {
  @apply text-base md:text-lg lg:text-xl mb-4;
}

.slider-sec {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden;
}
.slider-sec:before {
  @apply bg-fixed absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.title-heading {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.title-heading:before {
  @apply bg-fixed absolute bg-neutral-900 bg-opacity-10 bottom-0 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.parallex1 {
  @apply bg-cover bg-fixed bg-no-repeat bg-center relative overflow-hidden w-full;
}
.parallex1:before {
  @apply absolute bottom-0 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.col-effect {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden;
}
.col-effect:before {
  @apply bg-fixed absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}
.col-justify p {
  @apply text-justify;
}
.whitebox {
  @apply bg-white rounded-2xl mb-8 p-6 md:p-8;
  .title {
    @apply lg:text-base font-semibold text-black mb-5;
  }
}

.btn-theme1 {
  @apply font-medium text-black bg-white hover:bg-gray-900 hover:text-white py-4 px-4 lg:py-4 lg:px-10;
}
.btn-blue {
  @apply font-medium text-white bg-blue-500 hover:bg-blue-800 transition-all py-4 px-4 lg:py-4 lg:px-10;
}
.btn-red-lg {
  @apply font-medium text-white bg-red-600 hover:bg-red-800 transition-all uppercase py-4 px-4 lg:py-6 lg:px-8;
}
.btn-orange {
  @apply font-medium text-white bg-orange-600 hover:bg-orange-800 transition-all uppercase py-2 px-3;
}
.btn-red-sm {
  @apply font-medium text-white bg-red-600 hover:bg-red-800 transition-all text-sm py-2 px-4;
}
.btn-whoutline {
  @apply font-medium text-white border-2 border-white hover:bg-white w-full block transition-all text-sm uppercase text-center py-2 px-6;
}
.btn-blkline {
  @apply font-bold text-black hover:text-white border-2 border-black hover:bg-black transition-all uppercase py-4 px-4 lg:py-3 lg:px-10;
}

.szh-accordion__item {
  @apply rounded-md border border-gray-300 mb-3 p-4;
}
.szh-accordion__item h3 {
  @apply font-bold text-sm lg:text-base text-black;
}
.szh-accordion__item-content {
  @apply pt-4;
}

/* ================== PRODUCT CSS HERE ================== */
.products {
  .product-items {
    @apply bg-zinc-950 border border-zinc-800;
    .product-details {
      @apply p-4 md:p-6;
      h6 {
        @apply text-white font-medium;
      }
    }
  }
}

footer {
  @apply text-gray-800;
  h6.title {
    @apply font-bold text-white mb-8 uppercase;
  }

  ul.bottommenu {
    @apply flex items-center justify-start font-semibold my-6;
    li {
      @apply mx-4;
      a {
        @apply border-b border-white px-4 py-2 uppercase;
      }
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.lightbox-container {
  cursor: zoom-in;
}
.lightbox-container.zoomed-in {
  cursor: zoom-out;
}
